import React, { memo } from 'react';

import { styled } from '@mui/material/styles';

import {
  Card,
  CardContent,
  Typography,
  Rating,
  Box,
  CircularProgress,
  Tooltip, // Import Tooltip
} from '@mui/material';

import { ExpandedTerm, Term } from './term-progress-tracker';

// import LiveIcon from '@mui/icons-material/CheckCircle';

import LiveIcon from '@mui/icons-material/SentimentVerySatisfied';
import DeadIcon from '@mui/icons-material/SentimentVeryDissatisfied';

interface TermCardProps {
  term: Term;
}

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const TitleText = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexGrow: 1,
  marginRight: '8px',
});

const ContentContainer = styled('div')({
  maxHeight: '200px', // Adjust this value to approximate ten lines
  overflowY: 'auto',
});

const TermCard: React.FC<TermCardProps> = memo(({ term }) => {
  // Function to determine the group of a topic
  const getTopicGroup = (topic: any) => {
    if (topic.relevance_score != null) {
      return 1;
    } else if (topic.status != null) {
      return 2;
    } else {
      return 3;
    }
  };

  // Flatten all topics with their associated expanded terms
  const topicsWithExpandedTerm = term.expandedTerms.flatMap((expandedTerm) =>
    expandedTerm.topics.map((topic) => ({
      ...topic,
      expandedTermTerm: expandedTerm.term,
    }))
  );

  // Collect expanded terms without topics
  const expandedTermsWithoutTopics = term.expandedTerms.filter(
    (expandedTerm) => expandedTerm.topics.length === 0
  );

  // Sort the topics according to the specified groups and criteria
  const sortedTopics = [...topicsWithExpandedTerm].sort((a, b) => {
    const groupA = getTopicGroup(a);
    const groupB = getTopicGroup(b);
    if (groupA !== groupB) {
      return groupA - groupB;
    } else {
      if (groupA === 1) {
        // For group 1, sort by descending relevance_score
        return (b.relevance_score ?? 0) - (a.relevance_score ?? 0);
      } else if (groupA === 3) {
        // For group 3, sort alphabetically by topic name
        return a.name.localeCompare(b.name);
      } else {
        return 0; // Keep original order for group 2
      }
    }
  });

  return (
    <Card
      style={{
        marginBottom: '10px',
        width: '300px',
        display: 'inline-block',
      }}
    >
      <CardContent style={{ padding: '10px' }}>
        <TitleContainer>
          <TitleText variant="h6" title={term.term}>
            {term.term}
          </TitleText>
          {/* Add your icon buttons here */}
        </TitleContainer>

        <ContentContainer>
          {sortedTopics.map((topic, index) => (
            <Box
              key={`${topic.mid}-${index}`}
              display="flex"
              alignItems="center"
              mb={0.5}
            >
              <Tooltip title={topic.mid}>
                <Typography
                  variant="body2"
                  style={{ marginRight: '8px', flexGrow: 1 }}
                >
                  {topic.name}
                  {topic.name.toLowerCase() !==
                    topic.expandedTermTerm.toLowerCase()
                    ? ` (${topic.expandedTermTerm})`
                    : ''}
                </Typography>
              </Tooltip>
              {topic.relevance_score != null && (
                <>
                  {/* ((RR{topic.relevance_score}RR)) */}

                  <Rating
                    value={topic.relevance_score / 2}
                    readOnly
                    precision={0.5}
                    size="small"
                  />

                  {topic.status === 'live' && (
                    <LiveIcon
                      color="primary"
                      style={{
                        marginLeft: '8px',
                        color: 'green',
                      }}
                    />
                  )}
                  {topic.status === 'dead' && (
                    <DeadIcon
                      style={{ marginLeft: '8px', color: 'red' }}
                    />
                  )}
                  {topic.status === undefined && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: '8px' }}
                    />
                  )}
                </>
              )}
            </Box>
          ))}

          {expandedTermsWithoutTopics.map((expandedTerm) => (
            <Typography
              key={expandedTerm.term}
              variant="body2"
              style={{ marginLeft: '16px' }}
            >
              {expandedTerm.term}
            </Typography>
          ))}
        </ContentContainer>
      </CardContent>
    </Card>
  );
});

export default TermCard;
