import * as React from 'react';
import { Box } from '@mui/material';
import { KeywordDisplay } from './keyword-display';

interface KeywordListProps {
  keywords: string[];
  descriptions: { [key: string]: string };
}

export const KeywordList: React.FC<KeywordListProps> = ({ keywords, descriptions }) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {keywords.map((keyword, index) => (
        <Box key={index} margin={1}>
          <KeywordDisplay keyword={keyword} description={descriptions[keyword]} />
        </Box>
      ))}
    </Box>
  );
};
