// src/SSETest.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import TermProgressTracker, { TermProgressTrackerHandle } from './term-progress-tracker';

interface Message {
    event: string;
    update: any;
}

interface SSETestProps {
    onWorkflowEvent: (event: string, update: any) => void;
    onSortEvent: (event: string, update: any) => void;
}

// Styled component for the message list
const MessageList = styled(List)(({ theme }) => ({
    maxHeight: '4.5em', // Adjust based on line-height to fit approximately 3 lines
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    border: `1px solid ${theme.palette.divider}`, // Optional: Add a border for better visibility
}));

const ProgressUpdatesClient: React.FC<SSETestProps> = ({ onWorkflowEvent, onSortEvent }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const listRef = useRef<HTMLUListElement>(null);
    const trackerRef = useRef<TermProgressTrackerHandle>(null);

    useEffect(() => {
        // Construct the SSE URL
        const sseUrl = `/api/v1/sse`;
        const eventSource = new EventSource(sseUrl);

        eventSource.onmessage = (message) => {
            try {
                const parsedData = JSON.parse(message.data);
                const { event, update } = parsedData;

                // Update the local message list
                setMessages((prev) => {
                    const updatedMessages = [...prev, { event, update }];
                    // Keep only the latest 50 messages
                    if (updatedMessages.length > 50) {
                        return updatedMessages.slice(updatedMessages.length - 50);
                    }
                    return updatedMessages;
                });

                // Invoke the callback if the event is relevant
                if (event === 'workflow-completed' || event === 'workflow-failed') {
                    onWorkflowEvent(event, update);
                } else if (event === 'sort-progress') {
                    onSortEvent(event, update);
                } else {
                    trackerRef.current?.handleEvent(parsedData);
                }
            } catch (error) {
                console.error('Error parsing SSE message:', error);
            }
        };

        eventSource.onerror = (error) => {
            console.error('SSE error:', error);
            eventSource.close();
        };

        // Cleanup on component unmount
        return () => {
            console.log('Closing SSE connection');
            eventSource.close();
        };
    }, [onWorkflowEvent]);

    // Auto-scroll to the latest message when messages change
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages]);


    const handleTopicComplete = (mid: string) => {
        // Your logic here
    };

    return (
        <>
            {/* <Box>
                <Typography variant="h5" gutterBottom>
                    Status Updates
                </Typography>
                <MessageList ref={listRef}>
                    {messages.map((msg, index) => (
                        <ListItem key={index} disableGutters sx={{ padding: '0', margin: '0' }}>
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{ lineHeight: '1em', padding: '0', margin: '0' }} // Adjust lineHeight and spacing here
                            >
                                {JSON.stringify(msg)} <br />
                            </Typography>
                        </ListItem>
                    ))}
                </MessageList>
            </Box> */}

            <TermProgressTracker ref={trackerRef} onTopicComplete={handleTopicComplete} />
        </>
    );
};

export default ProgressUpdatesClient;
