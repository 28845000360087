import * as React from 'react';
import { Grid, Container, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProjectCard from './project-card';
import { useNavigate } from 'react-router-dom';
import { on } from 'events';

type Project = {
    name: string;
    description: string;
};

const mockProjects: Project[] = [
    { name: 'Project Alpha', description: 'This is the first project' },
    { name: 'Project Beta', description: 'This is the second project' },
    { name: 'Project Gamma', description: 'This is the third project' },
    { name: 'Project Delta', description: 'This is the fourth project' },
];

export const ProjectsList: React.FC = () => {
    const navigate = useNavigate();

    const handleAddProjectClick = () => {
      navigate('/project');
    };

    return (
        <Container>
            <Grid container spacing={4}>
                {mockProjects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ProjectCard project={project} />
                    </Grid>
                ))}
            </Grid>

            {/* Floating Action Button for creating new projects */}
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleAddProjectClick}
            >
                <AddIcon />
            </Fab>
        </Container>
    )
}
