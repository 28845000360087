import React, { memo } from 'react';
import { Card, CardContent, Box, Typography, IconButton, Icon, Rating } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import Cancel from '@mui/icons-material/Cancel';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styled } from '@mui/material/styles';
import { TopicStatus } from './topic-status';

interface TopicCardProps {
    mid: string;
    title: string;
    type?: string;
    description?: string;
    source?: string;
    status: TopicStatus;
    live?: boolean;
    terms: { term: string; relevance: number }[];
    relevance_score?: number;
    toggleTopicPinnedStatus: (mid: string) => void;
    toggleTopicExcludeStatus: (mid: string) => void;
}

// Styled components for truncating the title and ensuring icons are aligned
const TitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const TitleText = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    marginRight: '8px',
});

const IconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
});

const TopicCard: React.FC<TopicCardProps> = memo(({
    mid,
    title,
    type,
    description,
    source,
    status,
    live,
    terms,
    toggleTopicPinnedStatus,
    toggleTopicExcludeStatus
}) => {
    let details: string;

    details = `${mid} from ${source}`;

    if (type && !['Thing', 'SearchTerm'].includes(type)) {
        details += ` (${type})`;
    }

    // Group terms by name and calculate average relevance
    const groupedTerms = terms.reduce((acc: { [key: string]: { term: string; totalRelevance: number; count: number } }, { term, relevance }) => {
        if (!acc[term]) {
            acc[term] = { term, totalRelevance: relevance, count: 1 };
        } else {
            acc[term].totalRelevance += relevance;
            acc[term].count += 1;
        }
        return acc;
    }, {});

    // Convert the grouped terms into an array and calculate the average relevance
    const averagedTerms = Object.values(groupedTerms).map(({ term, totalRelevance, count }) => ({
        term,
        relevance: totalRelevance / count,
    }));

    // Sort the averaged terms by relevance
    averagedTerms.sort((a, b) => b.relevance - a.relevance);

    if (averagedTerms.length) {
        // Remove the relevance numbers from details
        details += ` (${averagedTerms.map(({ term }) => `${term}`).join(', ')})`;
    }

    // Grey out style if the topic is excluded
    const greyedOutStyle = status === TopicStatus.Excluded || !live
        ? { color: 'grey', opacity: 0.5 }
        : {};

    return (
        <Card key={mid} style={{ marginBottom: '10px', width: '300px', display: 'inline-block' }}>
            <CardContent style={{ padding: '10px', ...greyedOutStyle }}>
                <TitleContainer>
                    <TitleText variant="h6" title={title}>
                        {title}
                    </TitleText>
                    <IconContainer>
                        <IconButton
                            onClick={() => toggleTopicPinnedStatus(mid)}
                        >
                            {status === TopicStatus.Pinned ? (
                                <PushPinIcon
                                    color="secondary"
                                    sx={{ transform: 'rotate(45deg)', transition: 'transform 0.3s' }}
                                />
                            ) : (
                                <PushPinOutlinedIcon />
                            )}
                        </IconButton>
                        <IconButton
                            onClick={() => toggleTopicExcludeStatus(mid)}
                        >
                            {status === TopicStatus.Excluded ? <Cancel color="error" /> : <CancelOutlined />}
                        </IconButton>
                        <IconButton>
                            <AutorenewIcon />
                        </IconButton>
                    </IconContainer>
                </TitleContainer>

                {description && <Typography variant="body2">{description}</Typography>}
                <Typography variant="caption">{details}</Typography>

                {averagedTerms.length > 0 && (
                    <Box mt={1}>
                        {averagedTerms.map(({ term, relevance }) => (
                            <Box key={term} display="flex" alignItems="center" mb={0.5}>
                                <Typography variant="body2" style={{ marginRight: '8px' }}>
                                    {term}
                                </Typography>
                                <Rating
                                    name={`relevance-${term}`}
                                    precision={0.5}
                                    value={relevance / 2}
                                    max={5}
                                    readOnly
                                    size="small"
                                />
                            </Box>
                        ))}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
});

export default TopicCard;
