import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useIsAuthenticated } from './auth-context';

// Create a context for the workflow ID
const WorkflowIdContext = createContext<string | null>(null);

interface WorkflowIdProviderProps {
    workflowId?: string; // Optional initial workflow ID
    children: React.ReactNode; // Children components that need the workflow ID
}

// ensures children components have access to the workflow ID
// and that the workflow ID is valid before rendering the children
// will create a new workflow if no workflow ID is provided
export const WorkflowIdProvider: React.FC<WorkflowIdProviderProps> = ({ workflowId, children }) => {
    const [currentWorkflowId, setCurrentWorkflowId] = useState<string | null>(workflowId || null);
    const [isWorkflowValid, setIsWorkflowValid] = useState<boolean>(false);
    
    const isAuthenticated = useIsAuthenticated(); // Check authentication

    // Function to create a new workflow via POST
    const createWorkflow = async () => {
        try {
            const response = await axios.post<{ workflow_id: string }>('/api/v1/workflow', {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('appToken')}` }
            });
            return response.data.workflow_id;
        } catch (error) {
            console.error('Error creating workflow:', error);
            return null;
        }
    };

    // Function to check if the workflow exists and is valid
    const checkWorkflowExists = async (workflowId: string) => {
        try {
            await axios.get<{ exists: boolean }>(`/api/v1/workflow/${workflowId}/status`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('appToken')}` }
            });
            return true; // Assuming 'valid' status when the workflow exists
        } catch (error) {
            console.error('Error checking workflow status:', error);
            return false;
        }
    };

    useEffect(() => {
        const initializeWorkflow = async () => {
            let id = currentWorkflowId;

            // If no workflow ID is provided, create a new workflow
            if (!id) {
                id = await createWorkflow();
                if (id) setCurrentWorkflowId(id);
            }

            // Poll the server every 500 ms until the workflow is valid
            if (id) {
                const pollWorkflow = async () => {
                    const exists = await checkWorkflowExists(id);
                    if (exists) {
                        setIsWorkflowValid(true);
                    } else {
                        setTimeout(pollWorkflow, 500); // Poll again in 500 ms
                    }
                };
                pollWorkflow();
            }
        };

        initializeWorkflow();
    }, [currentWorkflowId]);

    // Block rendering if the user is not authenticated
    if (!isAuthenticated) {
        return <div>You need to be logged in to access this page.</div>;
    }

    // Block rendering if the workflow is invalid
    if (!isWorkflowValid) {
        return <div>Loading workflow...</div>;
    }

    console.log('Have valid workflow, workflow ID is', currentWorkflowId);

    return (
        <WorkflowIdContext.Provider value={currentWorkflowId}>
            {children}
        </WorkflowIdContext.Provider>
    );
};

// Custom hook to use the workflow ID in child components
export const useWorkflowId = () => {
    const context = useContext(WorkflowIdContext);
    if (!context) {
        throw new Error('useWorkflowId must be used within a WorkflowIdProvider');
    }
    return context;
};
