import * as React from 'react';
import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

interface SearchTermManagerProps {
    disabled?: boolean;
    onSearchTermsAdded: (terms: string[]) => void;
}

const SearchTermManager: React.FC<SearchTermManagerProps> = ({ disabled = false, onSearchTermsAdded }) => {
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');

    const handleSearchTermChange = (value: string) => {
        setCurrentSearchTerm(value);
    };

    const handleAddSearchTerm = () => {
        if (currentSearchTerm.trim() !== '') {
            onSearchTermsAdded([currentSearchTerm.trim()]);
            setCurrentSearchTerm('');
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAddSearchTerm();
        }
    };

    const handlePaste = (event: React.ClipboardEvent) => {
        const pasteText = event.clipboardData.getData('text');
        const splitSearchTerms = pasteText.split('\n').map(term => term.trim()).filter(term => term.length > 0);

        if (splitSearchTerms.length > 1) {
            // Multiple search terms pasted
            event.preventDefault();
            setCurrentSearchTerm('');
            onSearchTermsAdded(splitSearchTerms);
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" gap={1}>
                <TextField
                    value={currentSearchTerm}
                    onChange={(e) => handleSearchTermChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    disabled={disabled}
                    variant="outlined"
                    label="Search Term"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddSearchTerm}
                    disabled={disabled}
                >
                    <AddIcon /> Add search term
                </Button>
            </Box>
        </Box>
    );
};

export default SearchTermManager;