import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button,
    Typography,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Checkbox,
    Switch,
} from '@mui/material';



import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { MudlarkInterestOverTimeData, MudlarkTopic, Workflow } from '@mudlark/common';

import SearchTermManager from './search-term-manager';
import TopicManager from './topic-manager';
import { useWorkflowId, WorkflowIdProvider } from './workflow-id-provider';
import WorkflowResults from './workflow-result';
import ProgressUpdatesClient from './sse-test';
import { connection } from './auth-context';

type ProgressBarProps = {
    progress?: number | null;  // progress prop that accepts number or null/undefined
};

import ProjectStepper from './project-stepper';
import HelpText from './help-text';
import Chatbot from './chatbot';

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    if (progress == null) {
        // If progress is null or undefined, render nothing
        return null;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} />
        </Box>
    );
};

export const Project: React.FC = () => {
    const initialWorkflowId = useParams<{ workflowId: string }>().workflowId;

    return (
        <WorkflowIdProvider workflowId={initialWorkflowId}>
            {/* if ProjectInner is rendered, then
                - useWorkflowId is guaranteed to return an ID
                - that workflow ID is guaranteed to reference an workflow that exists
            */}
            <ProjectInner />
        </WorkflowIdProvider>
    );
};


// Define action types for the reducer
type Action =
    | { type: 'TOGGLE_SECTION'; index: number; }
    | { type: 'EXPAND_SECTION'; index: number; }
    | { type: 'NEXT_STEP'; index: number; }
    | { type: 'SET_STEP'; step: number };

// Define the initial state
type State = {
    expandedSections: boolean[];
    activeStep: number;
};

const initialState: State = {
    expandedSections: [true, ...Array(5).fill(false)],
    activeStep: 0,
};

// Reducer function to manage accordion and step state
function reducer(state: State, action: Action): State {
    switch (action.type) {
    case 'TOGGLE_SECTION':
        return {
            ...state,
            expandedSections: state.expandedSections.map((expanded, idx) =>
                idx === action.index ? !expanded : expanded
            ),
        };

    case 'EXPAND_SECTION': {
        const index = action.index;

        const updatedExpandedSections = [...state.expandedSections];
        updatedExpandedSections[index] = true;

        return {
            ...state,
            expandedSections: updatedExpandedSections,
        };
    }

    case 'NEXT_STEP': {
        const index = action.index;
        const nextStep = index + 1;

        const updatedExpandedSections = [...state.expandedSections];
        updatedExpandedSections[index] = false;
        updatedExpandedSections[nextStep] = true;

        return {
            expandedSections: updatedExpandedSections,
            activeStep: nextStep,
        };
    }

    case 'SET_STEP':
        return {
            ...state,
            activeStep: action.step,
        };

    default:
        return state;
    }
}


export const ProjectInner: React.FC = () => {
    const workflowId = useWorkflowId(); // this is guaranteed to reference a valid workflow

    console.log(`rendering project for workflow ID: ${workflowId}`);

    const pendingWorkflowUpdatePromisesRef = useRef<Promise<any>[]>([]);
    const isWorkflowRunningRef = useRef<boolean>(false);
    const userTermsRef = useRef<string[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [context, setContext] = useState<string | null>(null);
    const [details, setDetails] = useState<string | null>(null);
    const [topics, setTopics] = useState<MudlarkTopic[] | null>(null);
    const [analysis, setAnalysis] = useState<string | null>(null);
    const [recommendations, setRecommendations] = useState<string | null>(null);
    const [sortedKeywords, setSortedKeywords] = useState<string[]>([]);
    const [results, setResults] = useState<MudlarkInterestOverTimeData | null>(null);
    const [sortProgress, setSortProgress] = useState<number | null>(null);
    const [activeStep, setActiveStep] = useState<number>(0);

    const [selectedPeriod, setSelectedPeriod] = useState<string>('7');
    const [trendSources, setTrendSources] = useState<string[]>([]);
    const [autoTermsGeneration, setAutoTermsGeneration] = useState<boolean>(false);
    const [generateSearchTopics, setGenerateSearchTopics] = useState<boolean>(true);
    const [contextTouched, setContextTouched] = useState<boolean>(false);

    const isTopicGenerationNeededRef = useRef<boolean>(false);
    const isInterestOverTimeNeededRef = useRef<boolean>(false);

    const [isChatFinished, setIsChatFinished] = useState<boolean>(false);

    const [state, dispatch] = useReducer(reducer, initialState);

    const trendSourcesList = [
        { name: 'Google', value: 'google' },
        { name: 'TikTok', value: 'tiktok' },
        { name: 'Instagram', value: 'instagram' },
        { name: 'Reddit', value: 'reddit' },
        { name: 'Meta', value: 'meta' },
        { name: 'LinkedIn', value: 'linkedin' },
        { name: 'Your web analytics data', value: 'web_analytics' },
        { name: 'Your sales data', value: 'sales_data' },
    ];

    const getHandleAccordionChange = (stepIndex: number) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        dispatch({ type: 'TOGGLE_SECTION', index: stepIndex });
    };

    const handleChatFinished = (finished: boolean) => {
        setIsChatFinished(finished);
    };

    // Handle next step button click
    const getHandleNextStep = (stepIndex: number) => {
        return () => {
            dispatch({ type: 'NEXT_STEP', index: stepIndex });
        }
    }

    const handleTitleChange = (title: string) => {
        setTitle(title);
        updateWorkflow('title', title);
    };

    const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const period = event.target.value;
        setSelectedPeriod(period);

        const days = parseInt(period, 10);
        const today = new Date();
        const fromDateObj = new Date(today);
        fromDateObj.setDate(today.getDate() - days);

        const toDateStr = today.toISOString().split('T')[0];
        const fromDateStr = fromDateObj.toISOString().split('T')[0];

        setToDate(toDateStr);
        setFromDate(fromDateStr);

        // Send a single PATCH request to update the workflow with both toDate and fromDate
        updateWorkflowFields({ toDate: toDateStr, fromDate: fromDateStr });
    };

    const handleTrendSourceChange = (event: React.ChangeEvent<HTMLInputElement>, sourceValue: string) => {
        let updatedSources;
        if (event.target.checked) {
            updatedSources = [...trendSources, sourceValue];
        } else {
            updatedSources = trendSources.filter((s) => s !== sourceValue);
        }
        setTrendSources(updatedSources);
        updateWorkflow('trendSources', updatedSources);
    };

    const handleSearchTerm = (terms: string[]) => {
        dispatch({ type: 'EXPAND_SECTION', index: 3 });

        requestGenerateTopics(terms);
    };

    const requestGenerateTopics = async (terms: string[]) => {
        try {
            await Promise.all(pendingWorkflowUpdatePromisesRef.current);
        } catch (err) {
            console.error('Error updating workflow:', err);
            setError('An error occurred while updating the workflow');
            return;
        }

        isTopicGenerationNeededRef.current = true;
        userTermsRef.current = terms;

        setSortProgress(0);
        processNextWorkflowRequest();
    };

    const requestInterestOverTime = async () => {
        try {
            await Promise.all(pendingWorkflowUpdatePromisesRef.current);
        } catch (err) {
            console.error('Error updating workflow:', err);
            setError('An error occurred while updating the workflow');
            return;
        }

        isInterestOverTimeNeededRef.current = true;
        processNextWorkflowRequest();
    };

    // useCallback may be pointless - I'm not sure if workflowId can ever change
    const sendGenerateTopicsRequest = useCallback(
        async (terms: string[]) => {
            try {
                await axios.post(
                    `/api/v1/workflow/${workflowId}/generate-topics`,
                    { keywords: terms },
                    {
                        withCredentials: true,
                        headers: {
                            'X-Client-Id': connection.clientId,
                        },
                    }
                );
                setError(null);
            } catch (err) {
                console.error('Error sending generate topics request:', err);
                setError('An error occurred while sending the generate topics request');
                throw err;
            }
        },
        [workflowId]
    );

    // useCallback may be pointless - I'm not sure if workflowId can ever change
    const sendInterestOverTimeRequest = useCallback(async () => {
        try {
            await axios.post(
                `/api/v1/workflow/${workflowId}/interest-over-time`,
                { keywords: userTermsRef.current },
                {
                    withCredentials: true,
                    headers: {
                        'X-Client-Id': connection.clientId,
                    },
                }
            );
            setError(null);
        } catch (err) {
            console.error('Error sending interest over time request:', err);
            setError('An error occurred while sending the interest over time request');
            throw err;
        }
    }, [workflowId]);

    const fetchWorkflow = useCallback(async () => {
        try {
            const response = await axios.get<Workflow>(
                `/api/v1/workflow/${workflowId}`,
                {
                    withCredentials: true,
                    headers: {
                        'X-Client-Id': connection.clientId,
                    },
                });

            if (response.data) {
                setContext(response.data.context);
                setDetails(response.data.details);
                setToDate(response.data.toDate);
                setFromDate(response.data.fromDate);
                setTopics(response.data.topics);
                setAnalysis(response.data.analysis ?? null);
                setRecommendations(response.data.recommendations ?? null);
                setSortedKeywords(response.data.sortedKeywords ?? []);
                setResults(response.data.results ?? null);
                // setStep(response.data.state ?? '');
            }

            setError(null);
        } catch (err) {
            console.error('Error fetching workflow:', err);
            setError('An error occurred while fetching the workflow data');
        }
    }, [workflowId]);

    const updateWorkflow = useCallback(
        async (field: string, value: any) => {
            if (!workflowId) return;

            const promise = axios
                .patch(
                    `/api/v1/workflow/${workflowId}`,
                    { [field]: value },
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('appToken'),
                        },
                    }
                )
                .catch((err) => {
                    console.error(`Error updating workflow field ${field}:`, err);
                    setError('An error occurred while updating the workflow');
                });

            pendingWorkflowUpdatePromisesRef.current.push(promise);
        },
        [workflowId]
    );

    const updateWorkflowFields = useCallback(
        async (fields: Record<string, any>) => {
            if (!workflowId) return;

            const promise = axios
                .patch(
                    `/api/v1/workflow/${workflowId}`,
                    fields,
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('appToken'),
                        },
                    }
                )
                .catch((err) => {
                    console.error('Error updating workflow fields:', err);
                    setError('An error occurred while updating the workflow');
                });

            pendingWorkflowUpdatePromisesRef.current.push(promise);
        },
        [workflowId]
    );

    const processNextWorkflowRequest = useCallback(async () => {
        if (isWorkflowRunningRef.current) return;

        if (isTopicGenerationNeededRef.current) {
            isWorkflowRunningRef.current = true;
            isTopicGenerationNeededRef.current = false;

            try {
                await sendGenerateTopicsRequest(userTermsRef.current);
            } catch {
                isWorkflowRunningRef.current = false;
                return;
            }
        } else if (isInterestOverTimeNeededRef.current) {
            isWorkflowRunningRef.current = true;
            isInterestOverTimeNeededRef.current = false;

            try {
                await sendInterestOverTimeRequest();
            } catch {
                isWorkflowRunningRef.current = false;
                return;
            }
        } else {
            return;
        }
    }, [sendGenerateTopicsRequest, sendInterestOverTimeRequest]);

    const handleWorkflowEvent = useCallback(
        (event: string, update: any) => {
            if (event === 'workflow-completed' || event === 'workflow-failed') {
                isWorkflowRunningRef.current = false;
                fetchWorkflow();
                processNextWorkflowRequest();
            }
        },
        [fetchWorkflow, processNextWorkflowRequest]
    );

    const handleSortProgressEvent = (event: string, update: any) => {
        if (event === 'sort-progress') {
            setSortProgress(update.progress);
        }
    };

    useEffect(() => {
        fetchWorkflow();
    }, [fetchWorkflow, workflowId]);

    return (
        <Box sx={{ p: 3 }}>
            {error && (
                <Typography color="error" variant="body1">
                    {error}
                </Typography>
            )}

            <ProjectStepper activeStep={state.activeStep} />

            <Grid container spacing={2}>

                {/* About your business section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[0]} onChange={getHandleAccordionChange(0)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="trend-search-content"
                            id="trend-search-header"
                        >
                            <HelpText text="Tell us about your business and your trend search">
                                <Typography variant="h6">1. Trend search</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <HelpText text="Enter the name of your trend search here e.g., Autumn women’s fashion trends">
                                        <TextField
                                            label="Trend Search Title"
                                            placeholder="Enter trend search title"
                                            fullWidth
                                            value={title || ''}
                                            onChange={(event) => handleTitleChange(event.target.value)}
                                        />
                                    </HelpText>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <HelpText text="Select the period you want to analyze">
                                            <FormLabel component="legend">Select Period</FormLabel>
                                        </HelpText>
                                        <RadioGroup
                                            aria-label="period"
                                            name="period"
                                            value={selectedPeriod}
                                            onChange={handlePeriodChange}
                                        >
                                            <FormControlLabel value="7" control={<Radio />} label="Last seven days" />
                                            <FormControlLabel value="30" control={<Radio />} label="Last 30 days" />
                                            <FormControlLabel value="90" control={<Radio />} label="Last three months" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <HelpText text="Let us know a bit about your business">
                                        <Chatbot persona="trendsSearch" userMemoriesName="Business Memories" chatMemoriesName="Project Memories" chatMemoriesId={workflowId} onFinished={handleChatFinished} />

                                    </HelpText>
                                </Grid>

                                <Grid item xs={12}>
                                    <HelpText text="Provide additional details about your business">
                                        <TextField
                                            label="Details"
                                            placeholder="Give us more context, such as stock levels"
                                            multiline
                                            fullWidth
                                            rows={6}
                                            variant="outlined"
                                            value={details || ''}
                                            onChange={(event) => setDetails(event.target.value)}
                                            onBlur={() => updateWorkflow('details', details)}
                                        />
                                    </HelpText>
                                </Grid>

                                <Grid item xs={12}>
                                    <HelpText text={(context ?? '').trim() === '' ? 'Please provide us some context before proceeding' : 'Proceed to trend source selection'}>
                                        <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                            <Button
                                                variant="contained"
                                                onClick={getHandleNextStep(0)}
                                                disabled={!isChatFinished}
                                            >
                                                Next
                                            </Button>
                                        </span>
                                    </HelpText>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Trend sources section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[1]} onChange={getHandleAccordionChange(1)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="trend-sources-content"
                            id="trend-sources-header"
                        >
                            <HelpText text="Select the sources you want to include in your trend analysis">
                                <Typography variant="h6">2. Trend sources</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {trendSourcesList.map((source) => (
                                    <Grid item key={source.value}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={trendSources.includes(source.value)}
                                                    onChange={(event) => handleTrendSourceChange(event, source.value)}
                                                />
                                            }
                                            label={source.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <HelpText text={(context ?? '').trim() === '' ? 'Please provide us some context before proceeding' : 'Proceed to search terms selection'}>
                                <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                    <Button
                                        variant="contained"
                                        onClick={getHandleNextStep(1)}
                                        disabled={(context ?? '').trim() === ''}
                                    >
                                        Next
                                    </Button>
                                </span>
                            </HelpText>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Search terms section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[2]} onChange={getHandleAccordionChange(2)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="search-terms-content"
                            id="search-terms-header"
                        >
                            <HelpText text="Enter up to xx terms you are interested in tracking here. Or switch off and let us generate the top 100 terms across your chosen platforms">
                                <Typography variant="h6">3. Search terms</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Enter up to xx terms you are interested in tracking here. Or switch off and let us generate the top 100 terms across your chosen platforms
                                    </Typography>
                                </Grid> */}

                                <Grid item xs={12}>
                                    <HelpText text="Should we generate search terms for you, based on our understanding of your business">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={autoTermsGeneration}
                                                    onChange={(event) => setAutoTermsGeneration(event.target.checked)}
                                                />
                                            }
                                            label="Auto terms generation"
                                        />
                                    </HelpText>
                                </Grid>

                                <Grid item xs={12}>
                                    <HelpText text="Should we generation search topics for you, based on your search terms and our understanding of your business">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={generateSearchTopics}
                                                    onChange={(event) => setGenerateSearchTopics(event.target.checked)}
                                                />
                                            }
                                            label="Generate search topics"
                                        />
                                    </HelpText>
                                </Grid>

                                <Grid item xs={12}>
                                    <SearchTermManager
                                        disabled={(context ?? '').trim() === '' || autoTermsGeneration}
                                        onSearchTermsAdded={handleSearchTerm}
                                    />
                                    {(context ?? '').trim() === '' && (
                                        <Typography variant="caption" color="error">
                                            Please provide a context before adding search terms.
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <ProgressUpdatesClient
                                        onWorkflowEvent={handleWorkflowEvent}
                                        onSortEvent={handleSortProgressEvent}
                                    />
                                </Grid>

                            </Grid>

                            <HelpText text={(context ?? '').trim() === '' ? 'Please provide us some context before proceeding' : 'Proceed to search topics selection'}>
                                <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                    <Button
                                        variant="contained"
                                        onClick={getHandleNextStep(2)}
                                        disabled={(context ?? '').trim() === ''}
                                    >
                                        Next
                                    </Button>
                                </span>
                            </HelpText>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Search topics section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[3]} onChange={getHandleAccordionChange(3)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="search-topics-content"
                            id="search-topics-header"
                        >
                            <HelpText text="Topics help clarify search interest by identifying specific meanings, such as distinguishing 'Apple' the fruit from 'Apple' the tech company. Enabling topics can result in more accurate insights by focusing on the exact subject you're interested in.">

                                <Typography variant="h6">4. Search topics</Typography>

                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {topics ? (
                                        <TopicManager suggestedTopics={topics} />
                                    ) : (
                                        <Typography variant="body2">No search topics available.</Typography>
                                    )}
                                </Grid>
                            </Grid>

                            <Tooltip
                                title={(context ?? '').trim() === '' ? 'Please provide us some context to proceed.' : ''}
                                arrow
                            >
                                <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                    <Button
                                        variant="contained"
                                        onClick={getHandleNextStep(3)}
                                        disabled={(context ?? '').trim() === ''}
                                    >
                                        Next
                                    </Button>
                                </span>
                            </Tooltip>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Visual overview section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[4]} onChange={getHandleAccordionChange(4)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="visual-overview-content"
                            id="visual-overview-header"
                        >
                            <HelpText text="Get a visual overview of search interest over time for your search terms">
                                <Typography variant="h6">5. Visual overview</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={requestInterestOverTime}
                                        disabled={sortProgress !== 100 || (context ?? '').trim() === ''}
                                        title={(context ?? '').trim() === '' ? 'Please provide a context before continuing' : ''}
                                    >
                                        Get Search Interest Over Time
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    <ProgressBar progress={sortProgress} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Tooltip
                                        title={(context ?? '').trim() === '' ? 'Please provide us some context to proceed.' : ''}
                                        arrow
                                    >
                                        <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                            <Button
                                                variant="contained"
                                                onClick={getHandleNextStep(4)}
                                                disabled={(context ?? '').trim() === ''}
                                            >
                                                Next
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Analysis and recommendations section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[5]} onChange={getHandleAccordionChange(5)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="analysis-recommendations-content"
                            id="analysis-recommendations-header"
                        >
                            <HelpText text="Get a detailed analysis of your search interest over time and recommendations for your business">
                                <Typography variant="h6">6. Analysis and recommendations</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <WorkflowResults
                                workflowId={workflowId}
                                error={error ?? undefined}
                                // step={step}
                                analysis={analysis}
                                recommendations={recommendations}
                                sortedKeywords={sortedKeywords}
                                results={results}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    )
};