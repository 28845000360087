import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    TextField,
    IconButton,
    Paper,
    Typography,
    useMediaQuery,
    Theme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import ReactMarkdown from 'react-markdown'; // Import react-markdown

import axios from 'axios';
import { keyframes } from '@mui/system'; // Import keyframes for animations

interface ChatbotProps {
    persona: string;
    userMemoriesName: string;
    chatMemoriesName?: string;
    chatMemoriesId?: string | null;
    onFinished?: (isFinished: boolean) => void;
}

// Define keyframes for the typing animation
const bounce = keyframes`
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1.3); opacity: 1; }
  100% { transform: scale(1); opacity: 0.3; }
`;

// TypingIndicator Component
const TypingIndicator: React.FC = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
            }}
        />
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
                animationDelay: '0.2s',
            }}
        />
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
                animationDelay: '0.4s',
            }}
        />
    </Box>
);

const Chatbot: React.FC<ChatbotProps> = ({
    persona,
    userMemoriesName,
    chatMemoriesName,
    chatMemoriesId,
    onFinished,
}) => {
    const [messages, setMessages] = useState<{ user: string; text: string }[]>([]);
    const [message, setMessage] = useState('');
    const [chatId, setChatId] = useState<string | null>(null);
    const [userMemories, setUserMemories] = useState<{ [id: string]: string }>({});
    const [chatMemories, setChatMemories] = useState<{ [id: string]: string }>({});
    const [memoriesOpen, setMemoriesOpen] = useState(true); // Open by default
    const [loading, setLoading] = useState(false); // New loading state

    // Responsive breakpoint
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    // Ref to the end of the messages list
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // Scroll to the bottom whenever messages or loading state change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, loading]); // Added 'loading' to dependencies

    // Start a new chat when the component mounts
    useEffect(() => {
        const startChat = async () => {
            try {
                const payload = chatMemoriesId ? { persona, memories_id: chatMemoriesId } : { persona };

                const response = await axios.post('/api/v1/chat', payload);
                setChatId(response.data.chat_id);
                setMessages([{ user: 'Bot', text: response.data.message }]);

                // Fetch memories after the initial message from the bot
                await fetchMemories();

                if (onFinished) {
                    onFinished(false);
                }
            } catch (error) {
                console.error('Error starting chat:', error);
            }
        };

        startChat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch memories
    const fetchMemories = async () => {
        try {
            const userMemoriesResponse = await axios.get(`/api/v1/memories/user`);

            console.log('userMemoriesResponse', userMemoriesResponse.data.memories);

            setUserMemories(userMemoriesResponse.data.memories);

            if (chatMemoriesName && chatMemoriesId) {
                const chatMemoriesResponse = await axios.get(`/api/v1/memories/${chatMemoriesId}`);

                console.log(`chatMemoriesResponse ${chatMemoriesId}`, chatMemoriesResponse.data.memories);

                setChatMemories(chatMemoriesResponse.data.memories);
            }
        } catch (error) {
            console.error('Error fetching memories:', error);
        }
    };

    const handleSend = async () => {
        if (message.trim() && chatId) {
            // Add user message
            setMessages((prevMessages) => [...prevMessages, { user: 'You', text: message }]);
            setMessage('');
            setLoading(true); // Set loading to true when sending a message

            try {
                // Send the message to the chat API
                const response = await axios.post(`/api/v1/chat/${chatId}/message`, {
                    message,
                });

                // Add bot response
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { user: 'Bot', text: response.data.message },
                ]);
                setLoading(false); // Set loading to false after receiving the response

                // Check if the chat is finished
                if (response.data.is_finished && onFinished) {
                    onFinished(true); // Notify parent that the chat is finished
                }

                // Fetch memories after bot response
                await fetchMemories();
            } catch (error) {
                console.error('Error sending message:', error);
                setLoading(false); // Ensure loading is false even if there's an error
            }
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleToggleMemories = () => {
        setMemoriesOpen((prev) => !prev);
    };

    const handleDeleteUserMemory = async (id: string) => {
        try {
            await axios.delete(`/api/v1/memories/user/${id}`);
            setUserMemories((prevMemories) => {
                const updatedMemories = { ...prevMemories };
                delete updatedMemories[id];
                return updatedMemories;
            });
        } catch (error) {
            console.error('Error deleting user memory:', error);
        }
    };

    const handleDeleteChatMemory = async (id: string) => {
        try {
            if (chatMemoriesId) {
                await axios.delete(`/api/v1/memories/${chatMemoriesId}/${id}`);
                setChatMemories((prevMemories) => {
                    const updatedMemories = { ...prevMemories };
                    delete updatedMemories[id];
                    return updatedMemories;
                });
            }
        } catch (error) {
            console.error('Error deleting chat memory:', error);
        }
    };

    // Dynamic widths and heights based on screen size and panel state
    const memoriesPanelWidth = isLargeScreen
        ? memoriesOpen
            ? '25%' // Reduced width to allow chat area to expand more
            : '40px' // Collapsed width (thin vertical strip)
        : '100%'; // Full width on small screens

    const memoriesPanelHeight = isLargeScreen ? '100%' : memoriesOpen ? 'auto' : '40px'; // Handle height for small screens only

    // Placeholder when chat is not yet created
    if (!chatId) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Typography variant="h6">Loading chat...</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                height: '100%',
                mx: 'auto',
                width: '100%',
            }}
        >
            {/* Chat messages area */}
            <Box
                sx={{
                    flexGrow: 1,
                    flexBasis: '75%', // Allocate more space for the chat area
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Paper
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        display: 'flex', // Use flexbox layout
                        flexDirection: 'column', // Stack messages vertically
                        justifyContent: 'flex-end', // Align messages to the bottom
                        p: 2,
                        mb: 2,
                    }}
                >
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: msg.user === 'You' ? 'flex-end' : 'flex-start',
                                mb: 1,
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 1.5,
                                    backgroundColor: msg.user === 'You' ? 'primary.main' : 'secondary.main',
                                    color: 'primary.contrastText',
                                    borderRadius: 2,
                                    maxWidth: '70%',
                                }}
                            >
                                <ReactMarkdown>{msg.text}</ReactMarkdown>
                            </Paper>
                        </Box>
                    ))}

                    {/* Typing Indicator */}
                    {loading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                mb: 1,
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 1.5,
                                    backgroundColor: 'secondary.main',
                                    color: 'primary.contrastText',
                                    borderRadius: 2,
                                    maxWidth: '70%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <TypingIndicator />
                            </Paper>
                        </Box>
                    )}

                    {/* Ref to scroll to the bottom */}
                    <div ref={messagesEndRef} />
                </Paper>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={4}
                        label="Type your message"
                        variant="outlined"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        sx={{ mr: 1 }}
                    />
                    <IconButton color="primary" onClick={handleSend}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Memories panel */}
            <Box
                sx={{
                    width: memoriesPanelWidth,
                    display: 'flex',
                    flexDirection: 'column',
                    mt: { xs: 2, md: 0 },
                    ml: { xs: 0, md: 2 },
                    transition: 'width 0.3s ease-in-out',
                    overflow: memoriesOpen ? 'visible' : 'hidden',
                    flexGrow: 1,
                }}
            >
                {/* Header with Memories label and collapse button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: isLargeScreen && !memoriesOpen ? 'column' : 'row',
                        height: isLargeScreen && !memoriesOpen ? '100%' : 'auto',
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            writingMode:
                                isLargeScreen && !memoriesOpen ? 'vertical-rl' : 'horizontal-tb',
                            transform: 'none', // Remove rotation transforms for readability
                            textAlign: 'center',
                            mt: isLargeScreen && !memoriesOpen ? 'auto' : 0,
                            mb: isLargeScreen && !memoriesOpen ? 'auto' : 0,
                        }}
                    >
                        Memories
                    </Typography>
                    <IconButton
                        onClick={handleToggleMemories}
                        sx={{
                            transform: memoriesOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                            alignSelf: isLargeScreen && !memoriesOpen ? 'center' : 'flex-start',
                            mt: isLargeScreen && !memoriesOpen ? 0 : 'auto',
                        }}
                    >
                        <ExpandMoreIcon
                            sx={{
                                transform:
                                    !memoriesOpen && !isLargeScreen ? 'rotate(90deg)' : 'none',
                            }}
                        />
                    </IconButton>
                </Box>

                {/* Memories content */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        mt: memoriesOpen ? 2 : 0,
                        display: memoriesOpen ? 'flex' : 'none',
                        flexDirection: 'column',
                    }}
                >
                    {/* User Memories Section */}
                    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h6">{userMemoriesName}</Typography>
                            {userMemories && Object.keys(userMemories).length > 0 ? (
                                <ul>
                                    {Object.entries(userMemories).map(([id, memory]) => (
                                        <li key={id}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                                    {memory}
                                                </Typography>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleDeleteUserMemory(id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <Typography variant="body1">
                                    No {userMemoriesName} yet.
                                </Typography>
                            )}
                        </Paper>
                    </Box>

                    {/* Chat Memories Section */}
                    {chatMemoriesName && (
                        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="h6">{chatMemoriesName}</Typography>
                                {chatMemories && Object.keys(chatMemories).length > 0 ? (
                                    <ul>
                                        {Object.entries(chatMemories).map(([id, memory]) => (
                                            <li key={id}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ flexGrow: 1 }}
                                                    >
                                                        {memory}
                                                    </Typography>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            handleDeleteChatMemory(id)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Typography variant="body1">
                                        No {chatMemoriesName} yet.
                                    </Typography>
                                )}
                            </Paper>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Chatbot;
