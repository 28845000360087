import * as React from 'react';
import { Box, Typography } from '@mui/material';

type Project = {
  name: string;
  description: string;
};

type ProjectCardProps = {
  project: Project;
};

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '300px' }, // Just under screen width on small devices, fixed width on larger
        height: '150px', // Fixed height for consistency
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        backgroundColor: '#fff',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Typography
        variant="h6"
        noWrap
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          marginBottom: '8px',
        }}
      >
        {project.name}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: 'text.secondary',
          overflowWrap: 'break-word',
          flexGrow: 1,
        }}
      >
        {project.description}
      </Typography>
    </Box>
  );
};

export default ProjectCard;
