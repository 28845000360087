// AuthContext.tsx

import axios, { AxiosInstance } from 'axios';
import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';

export const connection: {
    client: AxiosInstance;
    clientId: string | null;
    login: (appKey: string) => Promise<void>;
    logout: () => Promise<void>;
} = {
    client: axios.create({ withCredentials: true }),
    clientId: '',
    login: async () => {},
    logout: async () => {},
};

// Define the shape of the authentication context
interface AuthContextProps {
    isAuthenticated: boolean;
}

// Create the authentication context
export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Custom hook for consuming the AuthContext
export const useIsAuthenticated = (): boolean => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context.isAuthenticated;
};

// AuthProvider component to wrap your application
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    console.log('AuthProvider rendering');

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true); // Optional: Manage loading state

    useEffect(() => {
        // On component mount, check if the user is already authenticated
        const checkAuthentication = async () => {
            try {
                console.log('Checking authentication via /api/v1/auth');

                const response = await axios.get('/api/v1/auth', {
                    withCredentials: true,
                });

                console.log('Authentication response:', JSON.stringify(response, null, 2));

                if (response.status === 200 && response.data.clientId) {
                    console.log('User is authenticated');

                    const fetchedClientId: string = response.data.clientId;
                    localStorage.setItem('clientId', fetchedClientId);

                    connection.clientId = fetchedClientId;

                    setIsAuthenticated(true);
                } else {
                    console.log('User is not authenticated');

                    connection.clientId = null;

                    setIsAuthenticated(false);
                }
            } catch (error: any) {
                console.error('Authentication error:', error);

                connection.clientId = null;

                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuthentication();
    }, []);

    // Login function to authenticate the user
    const login = async (appKey: string) => {
        console.log('Logging in with app key:', appKey);

        try {
            const clientId = localStorage.getItem('clientId');

            const response = await axios.post(
                '/api/v1/auth',
                { appKey, clientId },
                { withCredentials: true }
            );

            console.log('Login response:', JSON.stringify(response, null, 2));

            if (response.status === 200 && response.data.clientId) {
                console.log('Login successful');

                const fetchedClientId: string = response.data.clientId;
                localStorage.setItem('clientId', fetchedClientId);

                connection.clientId = fetchedClientId;

                setIsAuthenticated(true);
            } else {
                console.log('Login failed:', JSON.stringify(response, null, 2));
                
                const errorData = response.data;
                throw new Error(errorData.message || 'Invalid app key');
            }
        } catch (error: any) {

            connection.client = axios.create({ withCredentials: true });
            connection.clientId = null;

            setIsAuthenticated(false);

            console.error('Login error:', error);
            throw error;
        }
    };

    connection.login = login;

    // Logout function to unauthenticate the user
    const logout = async () => {
        console.log('Logging out');

        try {
            await connection.client.post('/api/v1/logout');

            connection.client = axios.create({ withCredentials: true });
            connection.clientId = null;
        } catch (error: any) {
            console.error('Logout error:', error);
            // Optionally handle logout errors, e.g., show a notification
        } finally {
            setIsAuthenticated(false);

            connection.client = axios.create({ withCredentials: true });
            connection.clientId = null;

            console.log('Cleared authentication state');
        }
    };

    connection.logout = logout;

    console.log(
        `AuthProvider ${isAuthenticated ? 'authenticated' : 'unauthenticated'} for client ID: ${connection.clientId} client:`, connection.client
    );

    if (isLoading) {
        return <div>Loading authentication status...</div>;
    }

    // Ensure that client and clientId are set when authenticated
    if (isAuthenticated) {
        return (
            <AuthContext.Provider
                value={{ isAuthenticated }}
            >
                {children}
            </AuthContext.Provider>
        );
    }

    // When not authenticated, still provide the context with appropriate values
    // Depending on your application, you might want to render children even when not authenticated
    return (
        <AuthContext.Provider
            value={{
                isAuthenticated
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
