import * as React from 'react'
import { useState, useContext } from 'react';
import { connection } from './auth-context';
import { Button, Container, Box } from '@mui/material';
import AppKeyInput from './app-key-input';

export const Login: React.FC = () => {
  const [appKey, setAppKey] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    try {
      await connection.login(appKey.trim());
    } catch (err) {
      console.error(err)
      setError('Invalid app key. Please try again.');
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <AppKeyInput
            appKey={appKey}
            setAppKey={setAppKey}
            error={error}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};
