import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import Chatbot from './chatbot';

export const BusinessInfo: React.FC = () => {
    const [businessName, setBusinessName] = useState<string>('');

    const handleBusinessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBusinessName(event.target.value);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Tell us about your business</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Business Name"
                        placeholder="Enter your business name"
                        fullWidth
                        value={businessName}
                        onChange={handleBusinessNameChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Chatbot persona="businessInfo" userMemoriesName="Business Memories" />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BusinessInfo;
