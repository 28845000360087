import React, { useState, useImperativeHandle, forwardRef, useReducer } from 'react';
import {
    Box,
} from '@mui/material';
import TermCard from './term-card';


export interface State {
    terms: { [term: string]: Term };
    expandedTerms: { [term: string]: ExpandedTerm };
    topics: { [mid: string]: Topic };
    termExpandedTerms: { [term: string]: string[] }; // Map from term to expanded term terms
    expandedTermTopics: { [term: string]: string[] }; // Map from expanded term to topic mids
}

// At the top of the file
export interface Topic {
    name: string;
    mid: string;
    relevance_score?: number;
    status?: 'live' | 'dead';
}

export interface ExpandedTerm {
    term: string;
    topics: Topic[];
}

export interface Term {
    term: string; // Original search term
    expandedTerms: ExpandedTerm[];
}

interface TermProgressTrackerProps {
    onTopicComplete?: (mid: string) => void;
}

export interface TermProgressTrackerHandle {
    handleEvent: (eventData: any) => void;
}

export type Action =
    | { type: 'TERM_ADDED'; payload: { term: string } }
    | { type: 'TERM_EXPANDED'; payload: { term: string; expandedTerms: string[] } }
    | { type: 'TOPICS_GENERATED'; payload: { expandedTerm: string; topics: Topic[] } }
    | { type: 'TOPICS_RATED'; payload: { term: string; topics: Topic[] } }
    | { type: 'INTEREST_PROBED'; payload: { mid: string; success: boolean } }
    | { type: 'WORKFLOW_COMPLETED' };

export function reducer(state: State, action: Action): State {
    const nextState = reducerActual(state, action);
    // console.log('Action:', JSON.stringify(action),'State:', JSON.stringify(nextState, null, 2));
    return nextState;
}

function reducerActual(state: State, action: Action): State {
    switch (action.type) {
        case 'TERM_ADDED': {
            const { term } = action.payload;
            if (state.terms[term]) {
                return state; // Term already exists
            }
            return {
                ...state,
                terms: {
                    ...state.terms,
                    [term]: { term, expandedTerms: [] },
                },
                termExpandedTerms: {
                    ...state.termExpandedTerms,
                    [term]: [],
                },
            };
        }
        case 'TERM_EXPANDED': {
            const { term, expandedTerms } = action.payload;
            const existingExpandedTerms = state.termExpandedTerms[term] || [];
            const newExpandedTerms = expandedTerms.filter(et => !existingExpandedTerms.includes(et));
            const updatedExpandedTerms = [...existingExpandedTerms, ...newExpandedTerms];

            // Create new ExpandedTerm objects
            const newExpandedTermObjects = newExpandedTerms.reduce((acc, et) => {
                acc[et] = { term: et, topics: [] };
                return acc;
            }, {} as { [term: string]: ExpandedTerm });

            // Initialize topics list for new expanded terms
            const newExpandedTermTopics = newExpandedTerms.reduce((acc, et) => {
                acc[et] = [];
                return acc;
            }, {} as { [term: string]: string[] });

            return {
                ...state,
                expandedTerms: {
                    ...state.expandedTerms,
                    ...newExpandedTermObjects,
                },
                termExpandedTerms: {
                    ...state.termExpandedTerms,
                    [term]: updatedExpandedTerms,
                },
                expandedTermTopics: {
                    ...state.expandedTermTopics,
                    ...newExpandedTermTopics,
                },
            };
        }
        case 'TOPICS_GENERATED': {
            const { expandedTerm, topics } = action.payload;
            const existingTopics = state.expandedTermTopics[expandedTerm] || [];
            const newTopicMids = topics.map(topic => topic.mid);
            const updatedTopics = [...new Set([...existingTopics, ...newTopicMids])];

            const newTopicObjects = topics.reduce((acc, topic) => {
                acc[topic.mid] = {
                    ...state.topics[topic.mid],
                    ...topic,
                };
                return acc;
            }, {} as { [mid: string]: Topic });

            return {
                ...state,
                topics: {
                    ...state.topics,
                    ...newTopicObjects,
                },
                expandedTermTopics: {
                    ...state.expandedTermTopics,
                    [expandedTerm]: updatedTopics,
                },
            };
        }
        case 'TOPICS_RATED': {
            const { term, topics } = action.payload;

            const newTopicObjects = topics.reduce((acc, topic) => {
                acc[topic.mid] = {
                    ...state.topics[topic.mid],
                    ...topic, // Update relevance_score
                };
                return acc;
            }, {} as { [mid: string]: Topic });

            return {
                ...state,
                topics: {
                    ...state.topics,
                    ...newTopicObjects,
                },
            };
        }
        case 'INTEREST_PROBED': {
            const { mid, success } = action.payload;
            if (!state.topics[mid]) {
                return state; // Topic doesn't exist
            }
            return {
                ...state,
                topics: {
                    ...state.topics,
                    [mid]: {
                        ...state.topics[mid],
                        status: success ? 'live' : 'dead',
                    },
                },
            };
        }
        case 'WORKFLOW_COMPLETED': {
            // Handle workflow completion if needed
            return state;
        }
        default:
            return state;
    }
}

export const initialState: State = {
    terms: {},
    expandedTerms: {},
    topics: {},
    termExpandedTerms: {},
    expandedTermTopics: {},
};

const TermProgressTracker = forwardRef<TermProgressTrackerHandle, TermProgressTrackerProps>(
    ({ onTopicComplete }, ref) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        // Expose handleEvent via ref
        useImperativeHandle(ref, () => ({
            handleEvent,
        }));

        const handleEvent = (eventData: any) => {
            const { event, update } = eventData;
            switch (event) {
                case 'term-added': {
                    console.log('term-added', update);
                    dispatch({ type: 'TERM_ADDED', payload: { term: update.term } });
                    break;
                }
                case 'term-expanded': {
                    console.log('term-expanded', update);
                    dispatch({
                        type: 'TERM_EXPANDED',
                        payload: { term: update.term, expandedTerms: update.expandedTerms },
                    });
                    break;
                }
                case 'topics-generated': {
                    console.log('topics-generated', update);
                    dispatch({
                        type: 'TOPICS_GENERATED',
                        payload: { expandedTerm: update.term, topics: update.topics },
                    });
                    break;
                }
                case 'topics-rated': {
                    console.log('topics-rated', update);
                    dispatch({
                        type: 'TOPICS_RATED',
                        payload: { term: update.term, topics: update.topics },
                    });
                    break;
                }
                case 'interest-probed': {
                    console.log('interest-probed', update);
                    dispatch({
                        type: 'INTEREST_PROBED',
                        payload: { mid: update.term, success: update.result.success },
                    });
                    break;
                }
                case 'workflow-completed': {
                    console.log('Workflow completed');
                    dispatch({ type: 'WORKFLOW_COMPLETED' });
                    break;
                }
                default:
                    break;
            }
        };

        // Prepare data for rendering
        const termsArray = Object.values(state.terms).map((term) => {
            const expandedTerms = (state.termExpandedTerms[term.term] || []).map((etTerm) => {
                const expandedTerm = state.expandedTerms[etTerm];
                const topicMids = state.expandedTermTopics[etTerm] || [];
                const topics = topicMids.map((mid) => state.topics[mid]);
                return {
                    ...expandedTerm,
                    topics: topics.sort(
                        (a, b) => (b.relevance_score || 0) - (a.relevance_score || 0)
                    ),
                };
            });
            return {
                ...term,
                expandedTerms,
            };
        });

        return (
            <Box
                display="flex"
                flexWrap="wrap"
                gap={2} // Optional: Adjust spacing between cards
            >
                {termsArray.map((term) => (
                    <TermCard key={term.term} term={term} />
                ))}
            </Box>
        );
    }
);

export default TermProgressTracker;