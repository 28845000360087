import * as React from 'react';
import { Typography, Box, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { InterestOverTimeStatistics, MudlarkInterestOverTimeData } from '@mudlark/common';
import ReactMarkdown from 'react-markdown'; // Import react-markdown
import { KeywordList } from './keyword-list';
import TimeSeriesChart from './time-series-chart';

const isTopic = (keyword: string) => keyword.startsWith('/g/') || keyword.startsWith('/m/');

const renderKeywordWithDescription = (keyword: string, descriptions: { [key: string]: string }) => {
  const description = descriptions[keyword] ?? keyword;

  if (isTopic(keyword)) {
    return `${description} (topic)`;
  } else {
    return `${description} (term)`;
  }
};

const renderHolidays = (holidays: { date: string; name: string }[]) => (
  <Box mt={4}>
    <Typography variant="h6">Holidays:</Typography>
    <List>
      {holidays.map((holiday, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={holiday.name}
            secondary={new Date(holiday.date).toLocaleDateString()}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

const renderStatistics = (statistics: InterestOverTimeStatistics, descriptions: { [key: string]: string }) => (
  <Box>
    <Typography variant="h6">Statistics:</Typography>

    <Box mt={2}>
      <Typography variant="subtitle1">Regression:</Typography>
      <List>
        {statistics.regression.map((reg) => (
          <ListItem key={reg.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(reg.keyword, descriptions)}
              secondary={`m: ${reg.m}, b: ${reg.b}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Ranking:</Typography>
      <List>
        {statistics.ranking.map((rank) => (
          <ListItem key={rank.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(rank.keyword, descriptions)}
              secondary={`mean: ${rank.mean}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Outliers:</Typography>
      <List>
        {statistics.outliers.map((outlier) => (
          <ListItem key={outlier.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(outlier.keyword, descriptions)}
              secondary={`Outliers: ${outlier.outliers.map(o => `${new Date(o.date)}: ${o.value}`).join(', ')}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Volatility:</Typography>
      <List>
        {statistics.volatility.map((vol) => (
          <ListItem key={vol.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(vol.keyword, descriptions)}
              secondary={`Volatility: ${vol.volatility}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Peak:</Typography>
      <List>
        {statistics.peak.map((peak) => (
          <ListItem key={peak.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(peak.keyword, descriptions)}
              secondary={`Peak: ${peak.peak}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Z-Score Initial:</Typography>
      <List>
        {statistics.zScoreInitial.map((zScore) => (
          <ListItem key={zScore.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(zScore.keyword, descriptions)}
              secondary={`Z-Score: ${zScore.zScore}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Positive Slope:</Typography>
      <List>
        {statistics.positiveSlope.map((slope) => (
          <ListItem key={slope.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(slope.keyword, descriptions)}
              secondary={`Slope: ${slope.slope}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Normalized Growth Rate:</Typography>
      <List>
        {statistics.normalizedGrowthRate.map((growthRate) => (
          <ListItem key={growthRate.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(growthRate.keyword, descriptions)}
              secondary={`Growth Rate: ${growthRate.growthRate}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Autocorrelations:</Typography>
      <List>
        {statistics.autocorrelation.map((autoCorr) => (
          <ListItem key={autoCorr.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(autoCorr.keyword, descriptions)}
              secondary={autoCorr.autocorrelations
                .map(ac => `Lag ${ac.lag} days: ${ac.autocorrelation.toFixed(4)}`)
                .join(', ')}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
);

type WorkflowResultsProps = {
  workflowId: string;
  error?: string;
  step?: string;
  analysis: string | null;
  recommendations: string | null;
  sortedKeywords: string[];
  results: MudlarkInterestOverTimeData | null;
};

const WorkflowResults: React.FC<WorkflowResultsProps> = ({
  workflowId,
  error,
  step,
  analysis,
  recommendations,
  sortedKeywords,
  results,
}) => {
  if (error) {
    return (
      <Box>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (step !== 'completed') {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (!results) {
    return (
      <Box>
        <Typography variant="h6">Loading full results...</Typography>
      </Box>
    );
  }

  const { keywords, statistics } = results;

  const descriptions = {}; // FIXME: Get descriptions from topics if available
  const holidays = statistics.holidays;

  return (
    <Box>
      <Box mt={4}>
        <Typography variant="h6">Keywords:</Typography>
        <KeywordList keywords={keywords} descriptions={descriptions} />
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Analysis:</Typography>
        <ReactMarkdown>{analysis ?? ''}</ReactMarkdown>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Recommendations:</Typography>
        <ReactMarkdown>{recommendations ?? ''}</ReactMarkdown>
      </Box>

      <Box mt={4}>
        <TimeSeriesChart data={results} descriptions={descriptions} />
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Sorted keywords:</Typography>
        {sortedKeywords
          .reverse()
          .map((keyword, index) => `#${index + 1}: ${renderKeywordWithDescription(keyword, descriptions)}`)
          .join(', ')}
      </Box>

      {holidays && holidays.length > 0 && <Box mt={4}>{renderHolidays(holidays)}</Box>}

      <Box mt={4}>{renderStatistics(statistics, descriptions)}</Box>
    </Box>
  );
};

export default WorkflowResults;
