import { MudlarkTopic } from "@mudlark/common";
import { get } from "http";

export type TopicWithStatus = MudlarkTopic & { status: TopicStatus };

export type TopicsState = {
    [mid: string]: TopicWithStatus;
}

export enum TopicStatus {
    Suggested = 'suggested',
    Pinned = 'pinned',
    Excluded = 'excluded',
}

function getTopicsUpdatedWithTopic(topics: TopicsState, mid: string, topic: TopicWithStatus): TopicsState {
    return {
        ...topics,
        [mid]: topic
    };
}

export function getUpdatedTopicsForStatusChange(topics: TopicsState, mid: string, status: TopicStatus): TopicsState {
    const topic = getTopic(topics, mid);

    return getTopicsUpdatedWithTopic(topics, mid, {...topic, status});
}

function getTopic(topics: TopicsState, mid: string) {
    const topic = topics[mid];

    if (!topic) {
        throw new Error(`Topic with mid ${mid} not found`);
    }

    return topic;
}

export function getUpdatedTopicsForToggledPinned(topics: TopicsState, mid: string) {
    const topic = getTopic(topics, mid);

    const status = topic.status === TopicStatus.Pinned ? TopicStatus.Suggested : TopicStatus.Pinned;

    return getTopicsUpdatedWithTopic(topics, mid, {...topic, status});
}

export  function getUpdatedTopicsForToggledExcluded(topics: TopicsState, mid: string) {
    const topic = getTopic(topics, mid);

    const status = topic.status === TopicStatus.Excluded ? TopicStatus.Suggested : TopicStatus.Excluded;

    return getTopicsUpdatedWithTopic(topics, mid, {...topic, status});
}
