import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, GlobalStyles } from '@mui/material';
import { AuthProvider, AuthContext } from './auth-context';
import { Project } from './project';
import { Login } from './login';
import { HubLayout } from './hub-layout';
import { ProjectsList } from './projects-list'; // Newly created component
import BusinessInfo from './business-info';

// Step 1: Create a base theme to access default breakpoints
const baseTheme = createTheme();

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          paddingLeft: baseTheme.spacing(1),
          paddingRight: baseTheme.spacing(1),
          [baseTheme.breakpoints.down('sm')]: {
            paddingLeft: `5px`,
            paddingRight: `5px`,
          },
        },
      }
    },
  },
});

const App: React.FC = () => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    return null; // You can return a loading state here if needed
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              fontSize: '1rem',
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem', // Larger font size for small screens
              },
              [theme.breakpoints.up('md')]: {
                fontSize: '1rem', // Standard font size for medium and larger screens
              },
            },
          }}
        />
        <Routes>
          {!authContext.isAuthenticated && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          )}
          {authContext.isAuthenticated && (
            <>
              <Route path="/" element={<HubLayout />}>
                <Route path="business-info" element={<BusinessInfo />} />
                <Route path="projects" element={<ProjectsList />} />
                <Route path="project" element={<Project />} />
                <Route path="archived" element={<div>Archived Projects</div>} />
                <Route path="settings" element={<div>User Settings</div>} />
                <Route path="/" element={<Navigate to="/projects" replace />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

const AuthenticatedApp: React.FC = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AuthenticatedApp;
