import * as React from 'react';
import { useReducer, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { MudlarkTopic } from '@mudlark/common';
import TopicCard from './topic-card';
import {
  getUpdatedTopicsForToggledExcluded,
  getUpdatedTopicsForToggledPinned,
  TopicsState,
  TopicStatus,
  TopicWithStatus,
} from './topic-status';


export type TopicManagerProps = {
  suggestedTopics: MudlarkTopic[];
  onKeywordsChange?: (keywords: string[]) => void;
};

type Action =
  | { type: 'TOGGLE_PINNED'; mid: string }
  | { type: 'TOGGLE_EXCLUDED'; mid: string }
  | { type: 'MERGE_SUGGESTED_TOPICS'; newTopics: MudlarkTopic[] };

function topicsReducer(state: TopicsState, action: Action): TopicsState {
  switch (action.type) {
    case 'TOGGLE_PINNED': {
      const { mid } = action;
      const updatedTopics = getUpdatedTopicsForToggledPinned(state, mid);
      return updatedTopics || state;
    }
    case 'TOGGLE_EXCLUDED': {
      const { mid } = action;
      const updatedTopics = getUpdatedTopicsForToggledExcluded(state, mid);
      return updatedTopics || state;
    }
    case 'MERGE_SUGGESTED_TOPICS': {
      return mergeNewSuggestedTopics(state, action.newTopics);
    }
    default:
      return state;
  }
}

function mergeNewSuggestedTopics(
  existingState: TopicsState,
  suggestedTopics: MudlarkTopic[]
): TopicsState {
  const mergedTopics = { ...existingState }; // Start with the current state

  for (const suggestedTopic of suggestedTopics) {
    const { mid } = suggestedTopic;
    let topic = existingState[mid];

    if (!topic) {
      topic = { ...suggestedTopic, status: TopicStatus.Suggested };

      mergedTopics[mid] = topic;
    } else {
      // Merge terms
      const existingTerms = topic.terms.map((x) => x.term);
      for (const term of suggestedTopic.terms) {
        if (!existingTerms.includes(term.term)) {
          topic.terms.push(term);
        }
      }
    }
  }

  return mergedTopics;
}

const TopicManager: React.FC<TopicManagerProps> = ({ suggestedTopics = [] }) => {
  const [topics, dispatch] = useReducer(topicsReducer, {});

  const toggleTopicPinnedStatusCallback = useCallback((mid: string) => {
    dispatch({ type: 'TOGGLE_PINNED', mid });
  }, []);

  const toggleTopicExcludeStatusCallback = useCallback((mid: string) => {
    dispatch({ type: 'TOGGLE_EXCLUDED', mid });
  }, []);

  useEffect(() => {
    // When suggestedTopics prop changes, merge new topics into existing state
    dispatch({ type: 'MERGE_SUGGESTED_TOPICS', newTopics: suggestedTopics });
  }, [suggestedTopics]);

  function getSortedTopics() {
    const topicsArray = Object.values(topics);

    // Ensure relevance_score is set
    for (const topic of topicsArray) {
      if (topic.relevance_score === undefined) {
        topic.relevance_score = topic.terms.reduce(
          (sum, termObj) => sum + (termObj.relevance || 0),
          0
        );
      }
    }

    // Separate topics into pinned, normal, and excluded
    const pinnedTopics: TopicWithStatus[] = [];
    const normalTopics: TopicWithStatus[] = [];
    const deadTopics: TopicWithStatus[] = [];
    const excludedTopics: TopicWithStatus[] = [];

    for (const topic of topicsArray) {
      if (topic.status === TopicStatus.Pinned) {
        pinnedTopics.push(topic);
      } else if (topic.status === TopicStatus.Excluded) {
        excludedTopics.push(topic);
      } else if (!topic.live) {
        deadTopics.push(topic);
      } else {
        normalTopics.push(topic);
      }
    }

    // Sorting function
    const sortByRelevanceTermId = (a: TopicWithStatus, b: TopicWithStatus) => {
      // Compare relevance_score (descending)
      const relevanceA = a.relevance_score || 0;
      const relevanceB = b.relevance_score || 0;
      if (relevanceA !== relevanceB) {
        return relevanceB - relevanceA;
      }

      // Compare title (alphabetical order)
      const termA = a.title.toLowerCase();
      const termB = b.title.toLowerCase();
      if (termA !== termB) {
        return termA.localeCompare(termB);
      }

      // Compare mid (topic ID)
      return a.mid.localeCompare(b.mid);
    };

    // Sort each section
    pinnedTopics.sort(sortByRelevanceTermId);
    normalTopics.sort(sortByRelevanceTermId);
    deadTopics.sort(sortByRelevanceTermId);
    excludedTopics.sort(sortByRelevanceTermId);

    // Concatenate sections: pinned + normal + excluded
    return [...pinnedTopics, ...normalTopics, ...deadTopics, ...excludedTopics];
  }

  return (
    <Box>
      <AnimatePresence>
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
        >
          {getSortedTopics().map((topic) => {
            const {
              title,
              type,
              description,
              source,
              status,
              mid,
              live,
              terms,
            } = topic;

            return (
              <motion.div
                key={mid}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <TopicCard
                  mid={mid}
                  title={title}
                  type={type}
                  description={description}
                  source={source}
                  status={status}
                  live={live}
                  terms={terms}
                  toggleTopicPinnedStatus={toggleTopicPinnedStatusCallback}
                  toggleTopicExcludeStatus={toggleTopicExcludeStatusCallback}
                />
              </motion.div>
            );
          })}
        </Box>
      </AnimatePresence>
    </Box>
  );
};

export default TopicManager;
